// IEで動かない対策
$.ajaxSetup({
  cache : false
});

// 変数設定
const result    = '#display';     //結果を表示する要素
const modalWrap = '#modal';       //モーダルウィンドウの Wrapper
const modalWin  = '.modalwindow'; //モーダルウィンドウ
// 各ボタンの変数
const addBtn    = '#addBtn';      //「新規登録」ボタン
const insertBtn = '#insertBtn';   //「新規登録」「新規登録する」ボタン
const editBtn   = '#editBtn';   //「編集」ボタン
const updateBtn = '#updateBtn'; //「編集」「更新する」ボタン
const closeBtn  = '#closeBtn'; //「閉じる」ボタン
const deleteBtn = '#deleteBtn'; //「ゴミ箱」ボタン

function modalResize(data){
  const w  = $(window).width();
  const h  = $(window).height();
  const cw = $(data).outerWidth();
  const ch = $(data).outerHeight();
  //取得した値をcssに追加する
  $(data).css({
    'left' : ((w - cw)/2) + 'px',
    'top'  : ((h - ch)/2) + 'px'
  });
}

// 閉じるボタンを押したらモーダルウィンドウを閉じるアクション（引数は閉じるボタンの id
function closeWindow(data){
  $(document).on('click', data, function() {
    $(modalWrap).empty().fadeOut(100);
  });
}

// ゴミ箱アイコンをクリックしたら該当 id を削除
function deleteTrash(button){
  $(document).on('click', button, function() {
    const id = $(this).attr('data-id');
    if ( $(this).attr('data-oldcode') != undefined ) { // データ属性 data-oldcode が存在する場合、連動するテーブルのデータを削除する
      const oldCode = $(this).attr('data-oldcode');
      $.post( './delete.php', { id, oldCode }, function(data){ resultShow(); });
      return;
    }
    //
    $.post( './delete.php', { id }, function(data){ resultShow(); });
    //
  });
}

// result.php の表示
function resultShow(){
  $.post( './result.php', function(data){
      $(modalWrap).empty().fadeOut(100);
      $(result).hide().html(data).fadeIn(400);
      getPageResult();
      setSortable();
  });
}

// get - page - result ページネーションがあるページで項目を削除したときに、そのページが存在しなくなったときページの最初へジャンプさせる
function getPageResult(){
  const getURL = location.search;
  const page = $('#totalPage').attr('data-page');
  let query = getURL.split('=');

  if ( page < query[1] ) {
    window.location.href = './index.php';
  }
}

 // 並び替え
function setSortable() {
  $('#draglist').sortable({
    revert    : 100,
    axis      : 'y',
    tolerance : 'pointer',
    opacity   : 0.8,
    handle    : '.list-drag',
    update    : function(){
      const sortitem = $(this).sortable('serialize');
      localStorage.setItem('sortitem', sortitem);
    }
  });
}
